(function ($) {

    // export
    $('.export-btn').click(function(e) {
        e.preventDefault();

        var $this = $(this),
            form = $this.closest('form').clone();

        $(document.body).append(form);
        form.hide();
        form.attr('action', $this.attr('href')).submit();
    });

    // change limit
    $('.order-limit').change(function() {
        $(this).closest('form').submit();
    });

})(jQuery);


// auto calculate total price
(function ($) {

    var prices = window.prices || null;
    if (prices == null)
        return;

    var price = function(code, qty) {
        var result = 0;

        for (var key in prices[code]) {
            if (qty >= prices[code][key].min_qty && qty <= prices[code][key].max_qty) {
                result = qty * parseInt(prices[code][key].price);
                break;
            }
        }

        return result;
    };

    // handle change quantity event
    var updatePriceTotal = function() {
        var total = parseInt($('.ship-cost').val() || 0);

        $('.product-qty').each(function() {
            var $this = $(this),
                code = $this.data('code'),
                qty = $this.val() || 0;

            total += price(code, qty);
        });

        $('.price-total').val(total);
    };

    $('.product-qty, .ship-cost').change(function() {
        updatePriceTotal();
    });

    $('.city-selector').change(function() {
        var shipCost = $(this).val() == 3
            ? window.shipCosts.shipCostHN : window.shipCosts.shipCost;

        $('.ship-cost').val(shipCost);
        updatePriceTotal();
    });

})(jQuery);


// multiple update status
(function ($) {

    // toggle panel control
    $('.or-check-control').change(function() {
        var checked = $(this).is(':checked');
        $('.or-check').prop('checked', checked);

        quickPanelToggle();
    });

    $('.or-check').change(function() {
        quickPanelToggle();
    });

    var quickPanelToggle = function() {
        if ($('.or-check:checked').length) {
            $('#quick-panel').slideDown(300);
        } else {
            $('#quick-panel').hide();
        }
    };

    // multiple update
    $('form', '#multipleUpdate').submit(function(event) {
        event.preventDefault();
        var form = $(this),
            ids = $('.or-check:checked').map(function() { return $(this).val(); }).get(),
            data = {
                status_id: $('[name=status_id]', form).val(),
                user_id: $('[name=user_id]', form).val()
            };

        $('[type=submit]', form).button('loading');

        $.post(form.attr('action'), {ids: ids, data: data}, function(result) {
            $('[type=submit]', form).button('reset');
            location.reload();
        }, 'JSON');
    });

    $('.show-more-btn', '#multipleUpdate').click(function(e) {
        $('.show-more-item', '#multipleUpdate').toggle();
    });

})(jQuery);
