$(function() {

    $('.color-picker').colorpicker().on('hidePicker', function(e) {
        var input = $('input', e.target);
        input.trigger('change');
    });

    // delete item
    $('.delete-btn').click(function(e) {
        e.preventDefault();

        var $this = $(this),
            item = $this.closest('.item');

        if (confirm('Bạn có chắc muốn xóa?')) {
            $.post($this.attr('href'), {}, function(deleted) {
                if (deleted)
                    item.remove();
            });
        }
    });

    // update item
    $('.inline-update').change(function() {
        var $this = $(this),
            name = $(this).attr('name'),
            data = {
                ids: [$this.data('id')],
                data: {}
            };

        data.data[name] = $.trim($this.val());

        $.post($this.data('url'), data, function(changed) {

        });
    });

    // get district options
    $('.city-selector').change(function() {
        var selector = $('.district-selector'),
            cityId = $(this).val();

        $.get(selector.data('url'), {cityId: cityId}, function(list) {
            $('.district-selector').html($(list));
        });
    });

    // select2
    $('.select2').each(function(i) {
        var $this = $(this);

        $this.select2({
            tags: $this.attr('select2-tag') == 'yep'
        });
    });

    // date range picker
    var start = moment($('[name=start_date]').val(), 'YYYY-MM-DD');
    var end = moment($('[name=end_date]').val(), 'YYYY-MM-DD');

    if (!start.isValid()) start = moment().subtract(14, 'days');
    if (!end.isValid()) end = moment();

    function datePickHandle(start, end) {
        $('#reportrange span').html(start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY'));
        $('[name=start_date]').val(start.format('YYYY-MM-DD'));
        $('[name=end_date]').val(end.format('YYYY-MM-DD'));
    }

    $('#reportrange').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            'Hôm nay': [moment(), moment()],
            'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            '7 ngày qua': [moment().subtract(6, 'days'), moment()],
            '14 ngày qua': [moment().subtract(13, 'days'), moment()],
            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
            'Tháng trước': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            format: 'DD/MM/YYYY'
        }
    }, datePickHandle);

    datePickHandle(start, end);
});